export const CREATE_SA_PERMISSION = gql`
  mutation CreateSentimentAnalysisPermissionMutation(
    $input: CreateSentimentAnalysisPermissionInput!
    $sendInvite: Boolean
  ) {
    createSentimentAnalysisPermission(input: $input, sendInvite: $sendInvite) {
      id
    }
  }
`

export const UPDATE_SA_PERMISSION = gql`
  mutation UpdateSentimentAnalysisPermissionMutation(
    $id: Int!
    $input: UpdateSentimentAnalysisPermissionInput!
  ) {
    updateSentimentAnalysisPermission(id: $id, input: $input) {
      id
    }
  }
`

export const AZURE_TOKEN_COUNT_BY_CLIENT = gql`
  query getAzureTokenCountByClient {
    azureTokenCountByClient(appName: EMAIL_SENTIMENT)
    sentimentAnalysisPermissionAppAgreement
  }
`

export const MEMBERSHIPS_BY_CLIENT = gql`
  query FindMembershipDetailsByClient {
    memberships: membershipsByClient {
      id
      user {
        name
        position
        avatarUrl
      }
    }
  }
`

export const UPDATE_ESI_RATING = gql`
  mutation UpdateESIRating(
    $id: Int!
    $input: UpdateLandlordEmailSentimentScoreInput!
    $adminFeedback: String
    $flaggedStatus: EmailFlagStatus
  ) {
    updateLandLordEmailSentimentScore(
      id: $id
      input: $input
      adminFeedback: $adminFeedback
      flaggedStatus: $flaggedStatus
    ) {
      id
      flaggedStatus
      flaggedEmail {
        adminFeedback
      }
    }
  }
`

export const REJECT_FLAGGED_EMAIL = gql`
  mutation RejectFlagEmail(
    $id: Int!
    $input: RejectLandlordFlaggedEmailInput!
  ) {
    rejectLandlordFlaggedEmail(id: $id, input: $input) {
      id
      adminFeedback
    }
  }
`

export const LANDLORDS_BY_MEMBERSHIP = gql`
  query FindLandlordsByMembership {
    landlords: landlordsWithEmailsAndSentimentScores {
      id
      name
    }
  }
`
