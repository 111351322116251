import { POLARITY } from 'api/src/common/enums'
import { checkSentimentScorePolarity } from 'api/src/common/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { sum } from 'ramda'
import {
  EmailFlagStatus,
  Landlord,
  SentimentStatusOptions,
} from 'types/graphql'

import { SelectionType } from '../Library/SelectWithSort/SelectWithSort'

import {
  FilterByOption,
  SASortOptions,
  SentimentAnalysisLabelThemes,
  SentimentAnalysisRatingOptions,
  SentimentAnalysisFlagOptions,
  SentimentStatusSelectOptions,
} from './SentimentAnalysisHelper'

export const validateTokenExpiry = (expiryDate: Date): boolean => {
  return dayjs().utc().isBefore(dayjs(expiryDate).utc())
}

export const getRatingStatus = (
  csiRating: number,
): SentimentAnalysisLabelThemes => {
  let labelTheme = SentimentAnalysisLabelThemes.danger
  if (checkSentimentScorePolarity(csiRating) === POLARITY.NEUTRAL)
    labelTheme = SentimentAnalysisLabelThemes.warning
  if (checkSentimentScorePolarity(csiRating) === POLARITY.POSITIVE)
    labelTheme = SentimentAnalysisLabelThemes.success

  return labelTheme
}

export const formatESIRecords = <
  T extends {
    id: number
    emails?: {
      csiRating?: number
      flaggedStatus?: EmailFlagStatus
    }[]
    landlordsWithEmailsAndSentimentScores?: any[]
    user?: any
    unprocessedEmailsCount?: number
    totalEmailsCount?: number
  },
>(
  users: T[],
) => {
  const usersWithContacts = users?.filter(
    (contact) => contact?.landlordsWithEmailsAndSentimentScores?.length > 0,
  )

  const activeMembers = usersWithContacts?.map((contact) => {
    const userLandlordScore =
      contact?.landlordsWithEmailsAndSentimentScores?.map(
        (landlord) => landlord?.csiRating,
      )
    const overallMemberCSIRating =
      Math.round((sum(userLandlordScore) / userLandlordScore?.length) * 100) /
      100

    const memberDetails: Landlord = {
      id: contact?.id,
      csiRating: overallMemberCSIRating,
      name: contact?.user?.name,
      emailCensored: contact?.user?.position,
      emails: [],
      unprocessedEmailsCount: contact?.unprocessedEmailsCount,
      totalEmailsCount: contact?.totalEmailsCount,
    }

    const allEmails = contact?.landlordsWithEmailsAndSentimentScores?.flatMap(
      (landlord) => {
        return landlord.emails
      },
    )

    return { ...contact, memberDetails, emails: allEmails }
  })

  return activeMembers
}

export const sortActiveESIRecords = <
  T extends {
    name?: string
    user?: {
      name?: string
    }
    csiRating?: number
    memberDetails?: any
    emails?: any[]
  },
>(
  contacts: T[],
  sortByValues: SelectionType,
  isMemberView = false,
) => {
  const sortedContacts = [...contacts].sort((a, b) => {
    const sortTrue = sortByValues.asc ? 1 : -1
    const sortFalse = sortByValues.asc ? -1 : 1

    if (sortByValues.value === SASortOptions.name) {
      if (isMemberView) {
        return a?.name > b?.name ? sortTrue : sortFalse
      } else {
        return a?.user.name > b?.user.name ? sortTrue : sortFalse
      }
    }

    if (sortByValues.value === SASortOptions.esi) {
      if (isMemberView) {
        return a?.csiRating > b?.csiRating ? sortTrue : sortFalse
      } else {
        return a?.memberDetails?.csiRating > b?.memberDetails?.csiRating
          ? sortTrue
          : sortFalse
      }
    }

    if (sortByValues.value === SASortOptions.latest) {
      // Emails are already ordered by newest
      const emailFieldName = isMemberView
        ? 'emails'
        : 'landlordsWithEmailsAndSentimentScores'
      const newestEmailA = a?.[emailFieldName][0]
      const newestEmailB = b?.[emailFieldName][0]

      return newestEmailA.receivedAt < newestEmailB.receivedAt
        ? sortTrue
        : sortFalse
    }

    return 0
  })

  return sortedContacts
}

export const handleFilterEmailByRating = <T extends { csiRating?: number }>(
  email: T,
  filterESIRating: SentimentAnalysisRatingOptions,
) => {
  if (filterESIRating === SentimentAnalysisRatingOptions.ALL) {
    return true
  }

  const ratingPolarity = checkSentimentScorePolarity(email.csiRating)

  return ratingPolarity === filterESIRating
}

export const filterESIRecords = <
  T extends {
    id: number
    emails?: {
      csiRating?: number
      flaggedStatus?: EmailFlagStatus
    }[]
    landlordsWithEmailsAndSentimentScores?: {
      csiRating?: number
      sentimentStatus?: SentimentStatusOptions
    }[]
  },
>(
  contacts: T[],
  filterOptions: FilterByOption[],
  sortByValues: SelectionType,
  filterESIRating: SentimentAnalysisRatingOptions,
  filterByFlag: SentimentAnalysisFlagOptions | EmailFlagStatus,
  filterByClientStatus: SentimentStatusSelectOptions | SentimentStatusOptions,
  isMemberView = false,
) => {
  const filterContactsWithIds = filterOptions.map((contact) => contact.id)

  const contactsFilteredBySelection =
    filterOptions.length === 0
      ? contacts
      : contacts.filter((contact) => {
          return filterContactsWithIds.includes(contact.id)
        })

  const contactsFilteredByESIRating =
    filterESIRating === SentimentAnalysisRatingOptions.ALL
      ? contactsFilteredBySelection
      : contactsFilteredBySelection.filter((contact) => {
          const emails = isMemberView
            ? contact.emails
            : contact.landlordsWithEmailsAndSentimentScores
          const emailsFilteredByRating = emails?.filter((email) =>
            handleFilterEmailByRating(email, filterESIRating),
          )

          if (emailsFilteredByRating.length > 0) {
            return true
          }
          return false
        })

  const formattedContacts = isMemberView
    ? contactsFilteredByESIRating
    : formatESIRecords(contactsFilteredByESIRating)

  const contactsFilteredByFlag = filterEmailsByFlag(
    filterByFlag as SentimentAnalysisFlagOptions,
    formattedContacts,
  )

  const contactsFilteredByClientStatus = filterLandlordsBySentimentStatus(
    filterByClientStatus as SentimentStatusSelectOptions,
    contactsFilteredByFlag,
  )

  const reSortedContacts = sortActiveESIRecords(
    contactsFilteredByClientStatus,
    sortByValues,
    isMemberView,
  )

  return reSortedContacts
}

const filterContactsByEmailStatus = <
  T extends {
    emails?: {
      flaggedStatus?: EmailFlagStatus
    }[]
  },
>(
  contacts: T[],
  statuses: SentimentAnalysisFlagOptions[],
): T[] => {
  return contacts.filter((contact) => {
    const emails = contact.emails
    const matchingEmails = emails?.filter((email) =>
      statuses.includes(email?.flaggedStatus as SentimentAnalysisFlagOptions),
    )
    return matchingEmails && matchingEmails.length > 0
  })
}

export const filterEmailsByFlag = <
  T extends {
    id: number
    emails?: {
      csiRating?: number
      flaggedStatus?: EmailFlagStatus
    }[]
    landlordsWithEmailsAndSentimentScores?: {
      csiRating?: number
    }[]
  },
>(
  filterByFlag: SentimentAnalysisFlagOptions,
  formattedContacts: T[],
): T[] => {
  switch (filterByFlag) {
    case SentimentAnalysisFlagOptions.ALL:
      return formattedContacts

    case SentimentAnalysisFlagOptions.FLAGGED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.FLAGGED,
      ])

    case SentimentAnalysisFlagOptions.REVIEWED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.APPROVED,
        SentimentAnalysisFlagOptions.REJECTED,
      ])

    case SentimentAnalysisFlagOptions.APPROVED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.APPROVED,
      ])

    case SentimentAnalysisFlagOptions.REJECTED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.REJECTED,
      ])

    case SentimentAnalysisFlagOptions.NOT_FLAGGED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.NOT_FLAGGED,
      ])

    default:
      return formattedContacts
  }
}

export const filterLandlordsBySentimentStatus = <
  T extends {
    id: number
    emails?: {
      csiRating?: number
      flaggedStatus?: EmailFlagStatus
    }[]
    landlordsWithEmailsAndSentimentScores?: {
      csiRating?: number
      sentimentStatus?: SentimentStatusOptions
    }[]
  },
>(
  filterByClientStatus: SentimentStatusSelectOptions,
  formattedContacts: T[],
): T[] => {
  switch (filterByClientStatus) {
    case SentimentStatusSelectOptions.ALL:
      return formattedContacts

    case SentimentStatusSelectOptions.AT_RISK:
      return filterLandlordsByClientSentimentStatus(
        formattedContacts,
        SentimentStatusSelectOptions.AT_RISK,
      )

    case SentimentStatusSelectOptions.IN_REVIEW:
      return filterLandlordsByClientSentimentStatus(
        formattedContacts,
        SentimentStatusSelectOptions.IN_REVIEW,
      )

    case SentimentStatusSelectOptions.NOT_IN_REVIEW:
      return filterLandlordsByClientSentimentStatus(
        formattedContacts,
        SentimentStatusSelectOptions.NOT_IN_REVIEW,
      )

    default:
      return formattedContacts
  }
}

const filterLandlordsByClientSentimentStatus = <
  T extends {
    landlordsWithEmailsAndSentimentScores?: {
      sentimentStatus?: SentimentStatusOptions
    }[]
    sentimentStatus?: SentimentStatusOptions
  },
>(
  contacts: T[],
  sentimentStatus: SentimentStatusSelectOptions,
): T[] => {
  return contacts.filter((contact) => {
    const landlords = contact.landlordsWithEmailsAndSentimentScores
    const landlordSentimentStatus = contact.sentimentStatus

    // Check if the contact matches the sentiment status
    const matchingContact = landlordSentimentStatus === sentimentStatus

    // Check if landlords match the sentiment status
    const matchingLandlords = landlords?.filter(
      (landlord) => landlord?.sentimentStatus === sentimentStatus,
    )

    // Combine the conditions: either the contact matches, or at least one landlord matches
    return (
      matchingContact || (matchingLandlords && matchingLandlords.length > 0)
    )
  })
}

export const getStatusColor = (
  landlordStatus: SentimentStatusOptions,
): string => {
  if (landlordStatus === 'IN_REVIEW') {
    return 'text-yellow-600 bg-yellow-100'
  } else if (landlordStatus === 'AT_RISK') {
    return 'text-red-600 bg-red-100'
  } else {
    return 'text-gray-600 bg-slate-100'
  }
}
