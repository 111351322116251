import React from 'react'

import FlagIcon from '@mui/icons-material/Flag'
import Chip from '@mui/material/Chip'
import { EmailFlagStatus } from 'types/graphql'
interface SentimentAnalysisEmailFlagStatusChipProps {
  status: EmailFlagStatus
  className?: string
}
const SentimentAnalysisEmailFlagStatusChip: React.FC<SentimentAnalysisEmailFlagStatusChipProps> =
  React.memo(({ status, className }) => {
    const toTitleCase = (str: string): string =>
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

    const statusLabel = toTitleCase(status)

    const statusColor = (status) => {
      switch (status) {
        case 'APPROVED':
          return 'bg-green-500'
        case 'REJECTED':
          return 'bg-orange-500'
        case 'FLAGGED':
          return 'bg-red-500'
        default:
          return 'bg-gray-500'
      }
    }

    return (
      <Chip
        label={statusLabel}
        icon={<FlagIcon className="h-4 w-4 fill-white" />}
        className={`${statusColor(status)} text-white ${className}`}
        size="small"
      />
    )
  })

export default SentimentAnalysisEmailFlagStatusChip
