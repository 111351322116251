import { FC } from 'react'

import {
  SentimentAnalysisBadgeClass,
  SentimentAnalysisBadgeProps,
} from '../SentimentAnalysisHelper'

const SentimentAnalysisBadge: FC<SentimentAnalysisBadgeProps> = ({
  title,
  value,
  variant,
  className = '',
}) => {
  return (
    <div
      className={`flex min-w-[100px] flex-row justify-center overflow-hidden rounded p-1 px-2 text-sm uppercase ${SentimentAnalysisBadgeClass[variant]} ${className}`}
    >
      {title && <div className="text-gray-500">{title}</div>}
      {value && <div className={'ml-1'}>{value}</div>}
    </div>
  )
}

export default SentimentAnalysisBadge
