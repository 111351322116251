import { create } from 'zustand'

import { SABucketOptions } from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'

interface SentimentSore {
  clearStore: () => void
  bucketOption: SABucketOptions
  setBucketOption: (bucketOption: SABucketOptions) => void
}

const useSentimentStore = create<SentimentSore>((set) => ({
  clearStore: () => set({ bucketOption: SABucketOptions.DAY }),
  bucketOption: SABucketOptions.DAY,
  setBucketOption: (bucketOption) => set({ bucketOption }),
}))

export default useSentimentStore
