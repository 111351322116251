export const GENERATE_AZURE_AUTH_CODE = gql`
  mutation CreateAzureTokenMutation($input: AzureGenerateAppCodeRequestInput!) {
    azureGenerateAppCodeRequest(input: $input) {
      url
    }
  }
`
export const DELETE_AZURE_TOKEN = gql`
  mutation DeleteAzureTokenMutation($appName: AzureAppName!) {
    deleteAzureToken(appName: $appName)
  }
`

export const ANALYSE_NEW_EMAILS = gql`
  mutation AnalyseNewEmailsMutation($addNewContacts: Boolean) {
    analyseNewEmails(addNewContacts: $addNewContacts)
  }
`

export const UPDATE_FEEDBACK = gql`
  mutation UpdateFeedback($id: Int!, $input: UpdateLandlordFlaggedEmailInput!) {
    updateLandlordFlaggedEmail(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_FLAGGED_EMAIL = gql`
  mutation ArchiveFlaggedEmail($id: Int!) {
    archiveLandlordFlaggedEmail(id: $id) {
      id
    }
  }
`
export const SEND_CONTACT_TO_BASEROW = gql`
  mutation SendContactToBaserowMutation($input: SendContactToBaserowInput!) {
    sendContactToBaserow(input: $input)
  }
`
