import { FC } from 'react'

interface SentimentAnalysisGraphTileProps {
  title: string
  value: string | number
  icon: JSX.Element
}

const SentimentAnalysisGraphTile: FC<SentimentAnalysisGraphTileProps> = ({
  title,
  value,
  icon,
}) => {
  return (
    <div className="flex items-center gap-4 rounded border p-3">
      {icon}
      <div>
        <p className="text-sm text-gray-500">{title}</p>
        <p
          className="text-md pt-1 font-bold"
          data-testid={`sa-overview-card-${title}-${value}`}
        >
          {value}
        </p>
      </div>
    </div>
  )
}

export default SentimentAnalysisGraphTile
