import { FC, useState } from 'react'

import {
  ChevronRightIcon,
  EyeIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import { Box, Chip, Tooltip } from '@mui/material'

import Button from 'src/components/Library/Button/Button'

import SendToHubdashConfirmDialog from '../Modals/SendToHubdashConfirmDialog'
import {
  SentimentAnalysisLabelThemes,
  SentimentAnalysisLandlordLabelProps,
  SentimentAnalysisMetric,
  SentimentAnalysisStatusOptionsDisplayMap,
} from '../SentimentAnalysisHelper'
import { getRatingStatus, getStatusColor } from '../SentimentAnalysisUtils'

import SentimentAnalysisBadge from './SentimentAnalysisBadge'

const SentimentAnalysisLandlordLabel: FC<
  SentimentAnalysisLandlordLabelProps
> = ({
  name = '',
  email = '',
  csiRating = 0,
  emailCount,
  id,
  onClick,
  activeLandlordId,
  landlordStatus,
}) => {
  const labelTheme = getRatingStatus(csiRating)
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)

  const handleClickOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setOpenConfirmDialog(true)
  }

  const isActive = id === activeLandlordId

  type LoadingState = {
    id: number
    status: boolean
  }
  const [isLoading, setIsLoading] = useState<LoadingState>({
    id: null,
    status: false,
  })

  const setLoadingStatus = (id: number, status: boolean) => {
    setIsLoading({
      ...isLoading,
      id: id,
      status: status,
    })
  }

  return (
    <>
      <div
        className={`border-b border-gray-200 ${
          isActive
            ? 'border-r border-r-violet-400'
            : 'border-r border-r-gray-200'
        }`}
      >
        <Box
          className={`flex flex-row items-start justify-start rounded-none p-2 pl-3 text-gray-600 hover:cursor-pointer hover:!bg-gray-100 ${
            isActive ? 'bg-violet-100 hover:!bg-violet-100' : ''
          }`}
          onClick={() => {
            onClick(isActive ? null : id)
          }}
        >
          <div className="flex w-full items-center justify-between gap-2">
            <div className="flex flex-wrap items-center gap-2">
              <p className="text-sm text-gray-800">{name}</p>
              <Tooltip title={email} placement="top">
                <InformationCircleIcon className="h-5 w-5 text-gray-500" />
              </Tooltip>
            </div>
            <div className="flex items-center gap-2">
              <Button
                size="small"
                className="max-h-[24px] max-w-[130px] flex-shrink rounded-md bg-indigo-100 text-indigo-500 hover:bg-indigo-200"
                loading={isLoading.id === id && isLoading.status}
                onClick={(event) => handleClickOpen(event)}
              >
                Send to Hubdash
              </Button>

              <Chip
                label={SentimentAnalysisStatusOptionsDisplayMap[landlordStatus]}
                size="small"
                className={`w-[120px] text-xs ${getStatusColor(landlordStatus)}`}
              />
              <SentimentAnalysisBadge
                title="Emails: "
                value={emailCount}
                variant={SentimentAnalysisLabelThemes.default}
                className="w-[120px] text-xs"
              />
              <SentimentAnalysisBadge
                title={`${SentimentAnalysisMetric.esi}:`}
                value={csiRating}
                variant={labelTheme}
                className="w-[120px] text-xs"
              />
              <div
                className="px-1"
                data-testid={`landlord-label-view-toggle-isActive-${isActive}`}
              >
                {isActive ? (
                  <EyeIcon className="h-5 w-5 text-indigo-600" />
                ) : (
                  <ChevronRightIcon className="h-5 w-5" />
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
      <SendToHubdashConfirmDialog
        id={id}
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        setLoadingStatus={setLoadingStatus}
      />
    </>
  )
}

export default SentimentAnalysisLandlordLabel
