import React, { FC, useMemo, useState } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import {
  SentimentEmailsQuery,
  SentimentEmailsQueryVariables,
} from 'types/graphql'

import { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as EmptyData } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import SentimentAnalysisGraph from 'src/components/SentimentAnalysis/SentimentAnalysisGraph/SentimentAnalysisGraph'
import {
  SABucketOptions,
  SADateFilterOptions,
  SentimentAnalysisFlagOptions,
  SentimentAnalysisRatingOptions,
  SentimentStatusSelectOptions,
} from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import SentimentAnalysisLandlordLabel from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisLandlordLabel'
import SentimentAnalysisPaginatedList from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisPaginatedList'
import { handleFilterEmailByRating } from 'src/components/SentimentAnalysis/SentimentAnalysisUtils'

import { type LandlordWithFlaggedEmails } from '../Fragments'

const variants = {
  open: { display: 'block', opacity: 1, height: '100%', minHeight: '288px' },
  closed: { display: 'hidden', opacity: 0, height: 0 },
  exit: { opacity: 0, height: 0, transitionEnd: { display: 'none' } },
}

export const QUERY = gql`
  query SentimentEmailsQuery(
    $filterDateRange: SADateFilterOptions
    $membershipId: Int!
  ) {
    landlords: landlordsWithEmailsAndSentimentScores(
      filterDateRange: $filterDateRange
      membershipId: $membershipId
    ) {
      ...LandlordFields
      emails {
        ...LandlordEmailFields
        flaggedEmail {
          ...LandlordFlaggedEmailWithoutResolversFields
        }
      }
    }
  }
`

export const Loading = () => (
  <div className="py-4">
    <LoadingSpinner />
  </div>
)

export const Empty = () => <EmptyData />

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type Props = CellSuccessProps<
  SentimentEmailsQuery,
  SentimentEmailsQueryVariables
> & {
  // Additional props this Cell receives
  filterESIRating: SentimentAnalysisRatingOptions
  filterDateRange?: SADateFilterOptions
  filterByFlag: SentimentAnalysisFlagOptions
  filterByClientStatus: SentimentStatusSelectOptions
  selectedBucket: SABucketOptions
  debouncedSearchText: string
}

export const Success: FC<Props> = ({
  landlords,
  filterESIRating,
  filterDateRange,
  filterByFlag,
  filterByClientStatus,
  selectedBucket,
  debouncedSearchText,
}) => {
  const filteredLandlords = useMemo(() => {
    // Filter by search text
    let landlordsFiltered = landlords
    if (debouncedSearchText.length > 0) {
      landlordsFiltered = landlords.filter((landlord) =>
        landlord.name.toLowerCase().includes(debouncedSearchText.toLowerCase()),
      )
    }

    // Filter by client status
    if (filterByClientStatus !== SentimentStatusSelectOptions.ALL) {
      landlordsFiltered = landlordsFiltered.filter(
        (landlord) => landlord.sentimentStatus === filterByClientStatus,
      )
    }

    // Filter emails by flag within each landlord object
    landlordsFiltered = landlordsFiltered
      .map((landlord) => {
        const filteredEmails =
          filterByFlag === SentimentAnalysisFlagOptions.ALL
            ? landlord.emails || []
            : (landlord.emails || []).filter(
                (email) =>
                  email.flaggedStatus === SentimentAnalysisFlagOptions.FLAGGED,
              )

        return {
          ...landlord,
          emails: filteredEmails,
        }
      })
      .filter((landlord) => landlord.emails.length > 0) // Exclude landlords with no matching emails

    // Filter by email ratings
    if (filterESIRating !== SentimentAnalysisRatingOptions.ALL) {
      landlordsFiltered = landlordsFiltered.filter((landlord) => {
        const filteredEmailsByRating = (landlord.emails || []).filter((email) =>
          handleFilterEmailByRating(email, filterESIRating),
        )

        return filteredEmailsByRating.length > 0
      })
    }

    return landlordsFiltered
  }, [
    landlords,
    debouncedSearchText,
    filterByClientStatus,
    filterByFlag,
    filterESIRating,
  ])

  const [selectedLandlord, setSelectedLandlord] =
    useState<LandlordWithFlaggedEmails>()

  const handleSelectLandlord = (landlordId: number) => {
    setSelectedLandlord(
      landlords.find((landlord) => landlord.id === landlordId),
    )
  }

  return (
    <div className="flex w-full grow flex-row-reverse justify-between gap-0">
      {selectedLandlord && (
        <SentimentAnalysisGraph
          selectedBucket={selectedBucket}
          filterDateRange={filterDateRange}
          emails={selectedLandlord?.emails.filter((email) =>
            handleFilterEmailByRating(email, filterESIRating),
          )}
          unprocessedEmailsCount={selectedLandlord?.unprocessedEmailsCount}
          tileTitle={
            <p className="pb-1 text-sm">
              <b>Viewing:</b> {selectedLandlord?.name}
            </p>
          }
        />
      )}

      {!selectedLandlord && (
        <SentimentAnalysisGraph
          selectedBucket={selectedBucket}
          filterDateRange={filterDateRange}
          emails={landlords.map((landlord) => landlord.emails).flat()}
          unprocessedEmailsCount={0}
          tileTitle={
            <p className="pb-1 text-sm">
              <b>Viewing:</b> All Contacts
            </p>
          }
        />
      )}

      <div className="flex max-h-[510px] grow flex-col gap-0 overflow-x-hidden overflow-y-scroll pt-0">
        {filteredLandlords.map((landlord, index) => (
          <React.Fragment key={landlord.id}>
            <SentimentAnalysisLandlordLabel
              key={index}
              id={landlord?.id}
              name={landlord?.name}
              email={landlord?.emailCensored}
              emailCount={landlord?.emails?.length}
              csiRating={landlord?.csiRating}
              activeLandlordId={selectedLandlord?.id}
              onClick={handleSelectLandlord}
              landlordStatus={landlord?.sentimentStatus}
            />
            <AnimatePresence>
              {selectedLandlord?.id === landlord?.id && (
                <motion.div
                  key={landlord.id}
                  variants={variants}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  transition={{ duration: 0.4 }}
                  className={'overflow-y-hidden border bg-gray-50 pb-1 pl-1'}
                >
                  {landlord?.emails ? (
                    <SentimentAnalysisPaginatedList
                      emails={landlord?.emails.filter((email) =>
                        handleFilterEmailByRating(email, filterESIRating),
                      )}
                      isAdminView
                    />
                  ) : (
                    <Loading />
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
