import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline'
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { useBoolean } from 'usehooks-ts'

import Button from '../Button/Button'

export interface SelectionType {
  value: string
  asc: boolean
}

export interface SelectWithSortOption {
  name: string
  value: string | number
}

interface SelectWithSortProps {
  label?: string
  options: SelectWithSortOption[]
  value: SelectionType
  onChange: Dispatch<SetStateAction<SelectionType>>
}

const SelectWithSort: FC<SelectWithSortProps> = ({
  label = 'Sort',
  options,
  value,
  onChange,
}) => {
  const [localValue, setLocalValue] = useState(value.value)
  const directionToggle = useBoolean(value.asc)

  const handleSelectPress = (newValue) => {
    setLocalValue(newValue)

    if (newValue !== localValue) {
      // Default to ASC on value change
      directionToggle.setTrue()
    }
  }

  useEffect(() => {
    onChange({ value: localValue, asc: directionToggle.value })
  }, [directionToggle.value, localValue])

  return (
    <FormControl
      fullWidth
      sx={{
        '& #select-with-sort-list button': {
          padding: '0px',
        },
      }}
    >
      <InputLabel id="select-with-sort-label" className="bg-white px-0.5">
        {label}
      </InputLabel>
      <Select
        className="min-w-[180px]"
        size="small"
        labelId="select-with-sort-label"
        id="select-with-sort-list"
        value={localValue}
        placeholder={label}
        endAdornment={
          <IconButton
            className={'absolute right-[30px] top-[3px]'}
            onClick={() => {
              directionToggle.toggle()
            }}
          >
            {directionToggle.value ? (
              <BarsArrowUpIcon className="h-5 w-5" />
            ) : (
              <BarsArrowDownIcon className="h-5 w-5" />
            )}
          </IconButton>
        }
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value} className="p-0">
              <Button
                variant="text"
                onClick={() => {
                  handleSelectPress(option.value)
                }}
                className="flex w-full items-center justify-between gap-3 p-2 px-3 text-base text-gray-700 hover:bg-transparent"
              >
                {option.name}
              </Button>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
export default SelectWithSort
