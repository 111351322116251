import React, { type FC } from 'react'

import { ArchiveBoxIcon } from '@heroicons/react/24/outline'
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import dayjs from 'dayjs'
import {
  type EmailFlagStatus,
  type LandlordEmailFields,
  type LandlordFlaggedEmailWithoutResolversFields,
} from 'types/graphql'

import Button from 'src/components/Library/Button'
import { SentimentAnalysisLabelThemes } from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import SentimentAnalysisBadge from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisBadge'
import { getRatingStatus } from 'src/components/SentimentAnalysis/SentimentAnalysisUtils'
import { Membership } from 'src/components/Settings/SentimentAnalysis/FlaggedSentimentEmailsByMemberCell/FlaggedSentimentEmailsByMemberCell'

import SentimentAnalysisEmailFlagStatusChip from './SentimentAnalysisEmailFlagStatusChip'

const HEALTH_RATINGS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

interface CommentProps {
  feedback?: string
  member?: Membership
  flaggedEmail?: LandlordFlaggedEmailWithoutResolversFields
}

const UserComment: FC<CommentProps> = ({ feedback, member, flaggedEmail }) => {
  return (
    <div className="rounded-lg border bg-white p-2 pb-4 text-base">
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center">
          <Avatar className={'mr-3 h-8 w-8'} src={member?.user?.avatarUrl} />
          <p className="mr-3 inline-flex items-center text-sm font-semibold text-gray-900">
            {member?.user?.name}
          </p>
          <p className="text-sm text-gray-500">
            Flagged:{' '}
            {dayjs(flaggedEmail?.createdAt).format('DD/MM/YYYY hh:mma')}{' '}
          </p>
        </div>
      </div>
      <p className="pt-2 text-gray-500 dark:text-gray-400">
        {feedback ? feedback : 'No feedback provided'}
      </p>
    </div>
  )
}

const AdminComment: FC<CommentProps> = ({ feedback, member, flaggedEmail }) => {
  return (
    <div className="rounded-lg border bg-white p-2 pb-4 text-base">
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center">
          <Avatar className={'mr-3 h-8 w-8'} src={member?.user?.avatarUrl} />
          <p className="mr-3 inline-flex items-center text-sm font-semibold text-gray-900">
            {member?.user?.name}
          </p>

          <p className="text-sm text-gray-500">
            Reviewed:{' '}
            {dayjs(flaggedEmail.reviewedDate).format('DD/MM/YYYY hh:mma')}{' '}
          </p>
        </div>
      </div>
      <p className="pt-2 text-gray-500 dark:text-gray-400">
        {feedback ? feedback : 'No feedback provided'}
      </p>
    </div>
  )
}

interface FlaggedEmailReviewModalViewOnlyProps {
  email: LandlordEmailFields
  flaggedEmail: LandlordFlaggedEmailWithoutResolversFields
  isOpen: boolean
  onClose: () => void
  member: Membership
  reviewedByMember: Membership
}

const FlaggedEmailReviewModalReadOnly: FC<
  FlaggedEmailReviewModalViewOnlyProps
> = ({ email, flaggedEmail, onClose, isOpen, member, reviewedByMember }) => {
  const labelTheme = getRatingStatus(flaggedEmail?.originalSentiment?.['nps'])

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <div
          className={
            'flex flex-row items-center justify-between gap-4 text-gray-500'
          }
        >
          <div className="flex items-center">
            {<ArchiveBoxIcon className={'mr-2 h-6 w-6'} />}
            <span className="text-md -mb-1 font-bold uppercase">
              Email: {dayjs(email?.receivedAt).format('DD/MM/YYYY hh:mma')}
            </span>
          </div>
          <SentimentAnalysisEmailFlagStatusChip
            status={email?.flaggedStatus as EmailFlagStatus}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col pt-2">
          <div className="mb-3 flex items-center justify-between">
            <FormLabel
              id="health-rating"
              sx={{
                textTransform: 'uppercase',
                marginBottom: '-0.5rem',
              }}
            >
              Health Rating
            </FormLabel>
            <SentimentAnalysisBadge
              title="Previous Health Rating"
              value={flaggedEmail?.originalSentiment?.['nps']}
              variant={SentimentAnalysisLabelThemes[labelTheme]}
              className="mb-1 text-center text-xs"
            />
          </div>
          <ToggleButtonGroup
            exclusive
            disabled={true}
            value={email?.sentimentScores['nps']}
            style={{ width: '100%' }}
          >
            {HEALTH_RATINGS.map((score) => {
              return (
                <ToggleButton
                  key={score}
                  value={score}
                  selected={email?.sentimentScores['nps'] === score}
                  style={{ flex: 1 }}
                >
                  {score}
                </ToggleButton>
              )
            })}
          </ToggleButtonGroup>
        </div>

        <div className="mt-6">
          <div className={'flex flex-col gap-4'}>
            <UserComment
              feedback={flaggedEmail?.feedback}
              member={member}
              flaggedEmail={flaggedEmail}
            />

            <AdminComment
              feedback={flaggedEmail?.adminFeedback}
              member={reviewedByMember}
              flaggedEmail={flaggedEmail}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className="flex flex-row pb-5">
        <Button
          fullWidth={false}
          variant={'outlined'}
          color={'secondary'}
          onClick={onClose}
          className="mr-4"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FlaggedEmailReviewModalReadOnly
