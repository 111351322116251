import React, { FC, useState } from 'react'

import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import InputAdornment from '@mui/material/InputAdornment'

import SelectWithSort, {
  SelectionType,
  SelectWithSortOption,
} from 'src/components/Library/SelectWithSort/SelectWithSort'
import { bucketHashMap } from 'src/components/SentimentAnalysis/SentimentAnalysisGraph/SentimentAnalysisGraphConfig'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useSentimentStore from 'src/lib/stores/sentimentStore'

import {
  FilterByOption,
  SABucketOptions,
  SADateFilterOptionNames,
  SADateFilterOptions,
  SASortOptions,
  SentimentAnalysisFilterBarProps,
  SentimentAnalysisRatingOptions,
  SentimentAnalysisFlagOptions,
  SentimentStatusSelectOptions,
  SentimentAnalysisStatusOptionsDisplayMap,
} from '../SentimentAnalysisHelper'

const SentimentAnalysisFlagDisplayMap: Record<
  SentimentAnalysisFlagOptions,
  string
> = {
  [SentimentAnalysisFlagOptions.ALL]: 'All',
  [SentimentAnalysisFlagOptions.FLAGGED]: 'Flagged',
  [SentimentAnalysisFlagOptions.REVIEWED]: 'Reviewed',
  [SentimentAnalysisFlagOptions.APPROVED]: 'Approved',
  [SentimentAnalysisFlagOptions.REJECTED]: 'Rejected',
  [SentimentAnalysisFlagOptions.NOT_FLAGGED]: 'Not Flagged',
}

const SentimentAnalysisFilterBar: FC<SentimentAnalysisFilterBarProps> = ({
  totalLandlordCount,
  filterByOptions,
  filterByValues,
  setFilterByValues,
  sortByValues,
  setSortByValues,
  filterDateRange,
  setFilterDateRange,
  filterESIRating,
  setFilterESIRating,
  selectedFlagFilter,
  setSelectedFlagFilter,
  filterByClientStatus,
  setFilterByClientStatus,
  adminView,
  currentSearchText,
  setCurrentSearchText,
  totalFilteredLandlordsCount,
}) => {
  // Autocomplete input value
  const [inputValue, setInputValue] = useState('')

  // initialize google analytics
  const { trackEvent } = useAnalytics()
  const [selectedBucket] = useSentimentStore((state) => [state.bucketOption])
  // Sort/Order options
  const sortOptions: SelectWithSortOption[] = [
    { name: 'Most Recent', value: SASortOptions.latest },
    { name: 'Name', value: SASortOptions.name },
    { name: 'Health Score', value: SASortOptions.esi },
  ]

  const handleDateFilterChange = (event: SelectChangeEvent) => {
    const newValue = event?.target?.value

    setFilterDateRange(SADateFilterOptions[newValue])

    sendAnalyticsEvent('Select Date Range', newValue)
  }

  const sendAnalyticsEvent = (eventName: string, analyticsLabel: string) => {
    trackEvent('Sentiment Analysis', eventName, {
      filterValue: analyticsLabel,
    })
  }

  const handleSortByChange = (selection: SelectionType) => {
    setSortByValues(selection)
    sendAnalyticsEvent(
      'Sort By',
      `value=${selection.value}, asc=${selection.asc}`,
    )
  }

  const handleFilterByESIRating = (event: SelectChangeEvent) => {
    const newValue = event?.target?.value
    setFilterESIRating(newValue as SentimentAnalysisRatingOptions)

    sendAnalyticsEvent('Select SA Rating Filter', newValue)
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  return (
    <div className="mb-4 flex grow items-center justify-between py-2">
      {!adminView && (
        <div>
          {totalLandlordCount !== null && (
            <>
              Showing&nbsp;
              <span className="font-bold">{totalFilteredLandlordsCount}</span>
              &nbsp;of
              <span className="font-bold"> {totalLandlordCount}</span> Contacts
            </>
          )}
        </div>
      )}
      {adminView && (
        <TextField
          placeholder="Search landlords"
          name="searchText"
          label="Search"
          onChange={(e) => {
            setCurrentSearchText(e.target.value)
          }}
          value={currentSearchText}
          className="mr-2 min-w-[180px] grow text-gray-700"
          size="small"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  {currentSearchText && (
                    <IconButton
                      className="cursor-pointer hover:bg-transparent"
                      onClick={() => setCurrentSearchText('')}
                    >
                      <XMarkIcon className="h-5 w-5 text-gray-700" />
                    </IconButton>
                  )}
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-700" />
                </InputAdornment>
              ),
            },
            inputLabel: { shrink: true },
          }}
        />
      )}
      <Grid container columnSpacing={1} rowSpacing={2} className="items-center">
        <div className="min-w-[200px]">
          <Autocomplete
            limitTags={1}
            fullWidth={false}
            size="small"
            multiple
            disableCloseOnSelect
            id="sa-landlord-select"
            options={filterByOptions}
            getOptionLabel={(option: FilterByOption) => option.name}
            value={filterByValues}
            onChange={(
              _event: React.ChangeEvent,
              newValue: FilterByOption[],
            ) => {
              setFilterByValues(newValue)
              let analyticsLabel: string
              if (newValue.length > 0) {
                analyticsLabel = newValue
                  .map((option) => option.name)
                  .join(', ')
              } else {
                analyticsLabel = 'none selected'
              }
              sendAnalyticsEvent('Filter By', analyticsLabel)
            }}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter By"
                className="capitalize"
                placeholder={filterByValues.length === 0 ? 'None Selected' : ''}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: filterByValues.length > 0 && (
                      <InputAdornment
                        position="start"
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          marginLeft: '8px',
                          color: '#111827', // Tailwind gray-900 equivalent
                        }}
                      >
                        <span>{`${filterByValues.length} Selected`}</span>
                      </InputAdornment>
                    ),
                  },
                  inputLabel: { shrink: true },
                }}
              />
            )}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props
              return (
                <li key={key} {...optionProps} className="capitalize">
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )
            }}
          />
        </div>
        <FormControl>
          <InputLabel id="sa-sort-label" className="bg-white px-0.5">
            Date Range
          </InputLabel>
          <Select
            className="min-w-[150px] text-gray-700"
            size="small"
            labelId="sa-sort-label"
            id="sa-sort-user-list"
            value={filterDateRange}
            onChange={handleDateFilterChange}
            placeholder="Date Range"
            data-testid="sa-sort-user-list"
          >
            <MenuItem value={SADateFilterOptions.ALL}>
              {SADateFilterOptionNames.ALL}
            </MenuItem>
            <MenuItem value={SADateFilterOptions.TODAY}>
              {SADateFilterOptionNames.TODAY}
            </MenuItem>
            <MenuItem value={SADateFilterOptions.WEEK}>
              {SADateFilterOptionNames.WEEK}
            </MenuItem>
            <MenuItem value={SADateFilterOptions.MONTH}>
              {SADateFilterOptionNames.MONTH}
            </MenuItem>
            <MenuItem value={SADateFilterOptions.YEAR}>
              {SADateFilterOptionNames.YEAR}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="sa-rating-label" className="bg-white px-0.5">
            Email Rating
          </InputLabel>
          <Select
            className="min-w-[120px] capitalize text-gray-700"
            size="small"
            labelId="sa-rating-label"
            id="sa-rating-list"
            value={filterESIRating}
            onChange={handleFilterByESIRating}
            placeholder="Rating"
            data-testid="sa-rating-list"
          >
            {Object.values(SentimentAnalysisRatingOptions).map(
              (ratingOption) => (
                <MenuItem
                  key={ratingOption}
                  value={ratingOption}
                  className={'capitalize'}
                >
                  {ratingOption}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
        <div>
          <SelectWithSort
            label="Sort By"
            options={sortOptions}
            value={sortByValues}
            onChange={handleSortByChange}
          />
        </div>
        <FormControl>
          <InputLabel id="sa-rating-label" className="bg-white px-0.5">
            Bucket
          </InputLabel>
          <Select
            fullWidth
            size="small"
            className="min-w-[80px] capitalize text-gray-700"
            value={selectedBucket}
            onChange={(event) => {
              useSentimentStore.setState({
                bucketOption: event.target.value as SABucketOptions,
              })
            }}
          >
            {Object.values(SABucketOptions)
              .filter((option) =>
                bucketHashMap.get(option)?.includes(filterDateRange),
              )
              .map((option) => (
                <MenuItem key={option} value={option} className={'capitalize'}>
                  {option}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="sa-rating-label" className="bg-white px-0.5">
            Client Status
          </InputLabel>
          <Select
            fullWidth
            size="small"
            className="min-w-[100px] capitalize text-gray-700"
            value={filterByClientStatus}
            onChange={(event) =>
              setFilterByClientStatus(
                event.target.value as SentimentStatusSelectOptions,
              )
            }
          >
            {Object.entries(SentimentAnalysisStatusOptionsDisplayMap).map(
              ([key, value]) => (
                <MenuItem key={key} value={key} className={'capitalize'}>
                  {value}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="sa-flags-label" className="bg-white px-0.5">
            Flags
          </InputLabel>
          <Select
            fullWidth
            size="small"
            value={selectedFlagFilter}
            aria-label="Filter by flag status"
            data-testid="sa-flag-filter"
            className="min-w-[80px] text-gray-700"
            onChange={(event) =>
              setSelectedFlagFilter(
                event.target.value as SentimentAnalysisFlagOptions,
              )
            }
          >
            {Object.values(SentimentAnalysisFlagOptions).map((option) => (
              <MenuItem key={option} value={option}>
                {SentimentAnalysisFlagDisplayMap[option]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </div>
  )
}

export default SentimentAnalysisFilterBar
