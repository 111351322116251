import { FC, useState } from 'react'

import {
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline'
import { FlagIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'
import type {
  GetEmailOutlookUrlQuery,
  GetEmailOutlookUrlQueryVariables,
  FindSentimentAnalysisQuery,
  LandlordEmailFields,
  LandlordFlaggedEmailWithoutResolversFields,
} from 'types/graphql'
import { useReadLocalStorage } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

import { SentimentAnalysisLabelThemes } from '../SentimentAnalysisHelper'
import { getRatingStatus } from '../SentimentAnalysisUtils'

import FlaggedEmailReviewModalReadOnly from './FlaggedEmailReviewModalReadOnly'
import FlaggedEmailUpdateOrDeleteModal from './FlaggedEmailUpdateOrDeleteModal'
import SentimentAnalysisBadge from './SentimentAnalysisBadge'

export const GET_EMAIL_OUTLOOK_URL = gql`
  mutation GetEmailOutlookUrlQuery($landlordEmailId: Int!) {
    getEmailOutlookUrl(landlordEmailId: $landlordEmailId)
  }
`

export const FLAG_EMAIL_FOR_REVIEW = gql`
  mutation CreateOrUpdateLandlordFlaggedEmail(
    $input: CreateOrUpdateLandlordFlaggedEmailInput!
  ) {
    createOrUpdateLandlordFlaggedEmail(input: $input) {
      id
    }
  }
`
export interface SentimentAnalysisEmailHeaderProps {
  id: number
  title: string
  csiRating: number
  isAdminView?: boolean
  email: LandlordEmailFields
  flaggedEmail?: LandlordFlaggedEmailWithoutResolversFields
  originalSentiment?: { nps: number }
  memberships?: FindSentimentAnalysisQuery['memberships']
}

const SentimentAnalysisEmailHeader: FC<SentimentAnalysisEmailHeaderProps> = ({
  id,
  title,
  csiRating,
  isAdminView = false,
  email,
  flaggedEmail,
  originalSentiment,
  memberships,
}) => {
  const [openLinkLoading, setOpenLinkLoading] = useState(false)
  const { trackEvent } = useAnalytics()
  // get user membershipId
  const { currentUser } = useAuth()

  const currentMembershipId = currentUser?.userData?.activeMembershipId

  // get the noAzureToken from local storage
  const noAzureToken: boolean = useReadLocalStorage(
    `noAzureToken-${currentMembershipId}`,
  )

  const [flaggedEmailReviewModalOpen, setFlaggedEmailReviewModalOpen] =
    useState(false)
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)

  const [flaggedEmailFeedback, setFlaggedEmailFeedback] = useState<string>(
    flaggedEmail?.feedback || '',
  )
  const [loading, setLoading] = useState(false)
  const labelTheme = getRatingStatus(csiRating)
  const previousRatingTheme = getRatingStatus(originalSentiment?.['nps'])

  const [getEmailOutlookUrl] = useMutation<
    GetEmailOutlookUrlQuery,
    GetEmailOutlookUrlQueryVariables
  >(GET_EMAIL_OUTLOOK_URL, {
    onCompleted: (data) => {
      openWindowWithBlockCheck(data.getEmailOutlookUrl)
      setOpenLinkLoading(false)
    },
    onError: (error) => {
      toast.error(error.message, { duration: 5000 })
      setOpenLinkLoading(false)
    },
  })

  const status = email?.flaggedStatus
  const isReviewed = status === 'APPROVED' || status === 'REJECTED'
  const reviewedAndRejected = isReviewed && status === 'REJECTED'
  const reviewedAndApproved = isReviewed && status === 'APPROVED'
  const reviewedAndChanged =
    reviewedAndApproved &&
    flaggedEmail?.originalSentiment?.['nps'] !== email?.sentimentScores?.['nps']

  const flagStatusColor = ({ status }) => {
    if (reviewedAndChanged || reviewedAndApproved) {
      return 'text-green-500'
    } else if (reviewedAndRejected) {
      return 'text-orange-500'
    } else {
      switch (status) {
        case 'FLAGGED':
          return 'text-red-500'
        default:
          return 'text-gray-300'
      }
    }
  }

  const getAdminViewTooltipTitle = (flaggedStatus: string) => {
    const statusMessages = {
      FLAGGED: 'Flagged',
      APPROVED: 'Approved',
      REJECTED: 'Rejected',
    }
    return statusMessages[flaggedStatus] ?? 'Not Flagged'
  }

  const getTooltipTitle = (flaggedStatus: string) => {
    const statusMessages = {
      FLAGGED: 'Awaiting review',
      APPROVED: 'Review approved',
      REJECTED: 'Review rejected',
    }
    return statusMessages[flaggedStatus] ?? 'Flag For Review'
  }

  const handleFlagEmail = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (isReviewed) {
      setFlaggedEmailReviewModalOpen(true)
      return
    } else {
      setFeedbackModalOpen(true)
      trackEvent('Sentiment Analysis', 'flag email for review')
    }
  }

  return (
    <>
      <FlaggedEmailReviewModalReadOnly
        email={email}
        flaggedEmail={flaggedEmail}
        onClose={() => setFlaggedEmailReviewModalOpen(false)}
        isOpen={flaggedEmailReviewModalOpen}
        member={memberships?.find(
          ({ id }) => id === flaggedEmail?.membershipId,
        )}
        reviewedByMember={memberships?.find(
          ({ id }) => id === flaggedEmail?.reviewedByMembershipId,
        )}
      />
      <FlaggedEmailUpdateOrDeleteModal
        isOpen={feedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
        email={email}
        flaggedEmailFeedback={flaggedEmailFeedback}
        setFlaggedEmailFeedback={setFlaggedEmailFeedback}
        loading={loading}
        setLoading={setLoading}
      />

      <div
        data-testid={`sentiment-analysis-email-header-${title}`}
        className="row flex w-full flex-wrap items-center justify-between gap-4"
      >
        <span className="mr-4 flex items-center gap-2 text-sm uppercase text-gray-500">
          <CalendarIcon className="h-4 w-4 text-gray-400" /> {title}
        </span>
        <div className="mr-2 flex flex-wrap items-center gap-2">
          {originalSentiment?.nps !== undefined &&
            originalSentiment.nps !== csiRating && (
              <Tooltip title="Previous Health Score" placement="top" arrow>
                <div>
                  <SentimentAnalysisBadge
                    title="Previous Health Score: "
                    value={originalSentiment?.nps}
                    variant={SentimentAnalysisLabelThemes[previousRatingTheme]}
                    className="w-[230px] text-center text-xs"
                  />
                </div>
              </Tooltip>
            )}

          <Tooltip title="Email Health Score" placement="top" arrow>
            <div>
              <SentimentAnalysisBadge
                title="Health: "
                value={csiRating}
                variant={SentimentAnalysisLabelThemes[labelTheme]}
                className="w-[70px] text-center text-xs"
              />
            </div>
          </Tooltip>
          {!isAdminView ? (
            <div className={'flex flex-row gap-0.5'}>
              <Tooltip
                title={getTooltipTitle(email?.flaggedStatus)}
                placement="top"
                arrow
              >
                <div>
                  <Button
                    fullWidth={false}
                    variant="text"
                    className="min-w-[0] hover:text-red-500"
                    onClick={handleFlagEmail}
                    loading={loading}
                  >
                    <FlagIcon
                      className={`h-4 w-4 ${flagStatusColor({ status: email?.flaggedStatus })}`}
                    />
                  </Button>
                </div>
              </Tooltip>
            </div>
          ) : (
            <Tooltip
              title={getAdminViewTooltipTitle(email?.flaggedStatus)}
              placement="top"
              arrow
            >
              <FlagIcon
                className={`mx-2 h-4 w-4 ${flagStatusColor({ status: email?.flaggedStatus })}`}
              />
            </Tooltip>
          )}
          <Tooltip
            title={(() => {
              if (noAzureToken) {
                return 'Authenticate to Open'
              }
              return 'Open in Outlook, this requires access to the email account'
            })()}
            placement="top"
            arrow
          >
            <div>
              <Button
                fullWidth={false}
                variant="text"
                color="secondary"
                className="min-w-[0]"
                disabled={noAzureToken}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setOpenLinkLoading(true)
                  getEmailOutlookUrl({
                    variables: {
                      landlordEmailId: id,
                    },
                  })
                  trackEvent('Sentiment Analysis', 'open email in outlook')
                }}
                loading={openLinkLoading}
              >
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  )
}

export default SentimentAnalysisEmailHeader
